import { ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

const backgroundProps = (props: ThemeProp & ShimmerProps) => {
  return `background: ${props.gradient || props.theme.gradients.shimmer};
      background-size: 1000px 100%;`;
};

export interface ShimmerProps {
  width?: string;
  height?: string;
  marginTop?: string;
  marginBottom?: string;
  borderRadius?: string;
  gradient?: string;
  disableFlex?: boolean;
  static?: boolean;
  marginLeft?: string;
}

export const ShimmerImage = styled(ShimmerThumbnail)<ShimmerProps>`
  && {
    display: block;
    &.shimmer {
      ${(props: ThemeProp) => backgroundProps(props)}
    }
    &.shimmer-thumbnail {
      min-width: unset;
      margin-top: ${(props: ShimmerProps) => props.marginTop || '0px'} !important;
      margin-bottom: ${(props: ShimmerProps) => props.marginBottom || '0px'} !important;
      width: ${(props: ShimmerProps) => props.width || '100%'};
      height: ${(props: ShimmerProps) => props.height || '100%'};
      ${(props: ShimmerProps) => props.static && 'animation: none !important;'}
    }
    border-radius: ${(props: ShimmerProps) => props.borderRadius || '0.25rem'};
  }
`;

export const ShimmerText = styled(ShimmerTitle).attrs(() => {
  return { line: 1 };
})<ShimmerProps>`
  && {
    flex: ${(props: ShimmerProps) => (!props.disableFlex ? '1' : 'unset')};
    & .shimmer {
      ${(props: ThemeProp) => backgroundProps(props)}
    }
    &.shimmer-title {
      margin-bottom: ${(props: ShimmerProps) => props.marginBottom || '0px'} !important;
    }
    & .shimmer-title-line {
      margin-top: ${(props: ShimmerProps) => props.marginTop || '0'} !important;
      height: ${(props: ShimmerProps) => props.height || '1rem'};
      border-radius: ${(props: ShimmerProps) => props.borderRadius || '0.25rem'};
      margin-left: ${(props: ShimmerProps) => props.marginLeft || '0'} !important;
      &:first-child {
        width: ${(props: ShimmerProps) => props.width || '100%'} !important;
      }
      ${(props: ShimmerProps) => props.static && 'animation: none !important;'}
    }
  }
`;
